/**
 * Styles for a card component.
 */

.card {
  background-color: #FFE8D6;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin-bottom: 10px;
  }
  
  .card-header {
    font-weight: bold;
    padding: 0.5rem 1rem;
  }
  
  .card-body {
    padding: 1rem;
  }
  
  .card-footer {
    padding: 0.5rem 1rem;
    text-align: right;
  }