/**
 * Styles for the menu components.
 */

.menu-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.menu-item {
    padding: 10px;
}

.menu-item .button-component {
    margin-right: 10px;
}

.menu-item-right {
    position: relative;
    float: right;
    text-align: right;
}