/**
 * Styles for the root page.
 */

.root-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80vw;
    margin: 0 auto;
}

.root-page .image-component {
    float: right;
    margin-left: 20px;
    border-radius: 8px;
    width: 30vw;
}

.root-page .root-footer {
    margin-top: 10px;
}

.root-page .root-footer .button-component {
    margin-left: 10px;
}
