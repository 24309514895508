/**
 * Styles for a button component.
 */

.button-component {
  border: solid 1px #6B705C;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bolder;
}

.button-component.primary {
  background-color: #CB997E;
  color: black;
  border-radius: 8px;
  padding: 10px 20px;
}

.button-component.primary:hover {
  background-color: #DDBEA9;
}

.button-component.link {
  color: #CB997E;
  border: none;
  background-color: transparent;
}

.button-component.link:hover {
  color: #DDBEA9;
}
