/**
 * Styles for the tosk pages.
 */

.tosk-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vw;
  margin: 0 auto;
}

.tosk-page-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tosk-page-body {
  width: 60vw;
}

.tosk-input {
  width: 100%;
  border-radius: 8px;
}

.tosk-page-body-footer {
  text-align: right;
}

.tosk-page-body textarea {
  width: 100%;
}

.tosk-page-body.tosk-container {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
}

.tosk-container textarea {
  width: 100%;
  resize: none;
  outline: none;
}

.tosk-page-body textarea {
  width: 100%;
  resize: none;
  outline: none;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.image-card {
  width: 30%;
  justify-content: center;
  margin: 10px;
}

.image-card img {
  width: 100%;
  height: fit-content;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .tosk-page-header {
    flex-direction: column;
  }
  
  .image-card {
    width: 100%;
  }
}
