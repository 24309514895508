/**
 * Styles for a success message component.
 */

.success-message {
    background-color: rgb(0, 183, 0);
    color: black;
    font-size: 0.8rem;
    font-weight: bolder;
    margin-top: 10px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid black;
    position: relative;
}
