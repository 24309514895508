/**
 * Styles for an error message component.
 */

.error-message {
    background-color: rgb(255, 76, 76);
    color: black;
    font-size: 0.8rem;
    font-weight: bolder;
    margin-top: 10px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid black;
    position: relative;
}
