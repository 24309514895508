/**
 * Styles for the login page.
 */

.login-page {
  display: grid;
  /* Splits the page into two columns, 50% each */
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: center;
}

/* Set the background image and adjust the size and position */
.login-page__image {
  background-image: url('../../assets/images/squirrel_800.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh; 
}

/* Adjust the styles of the login form */
.login-page__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Adjust the styles of the label form */
label {
  display: block;
  margin-bottom: 10px;
}

/* Media query for small screens */
@media (max-width: 767px) {
  /* Change the grid layout to a single column */
  .login-page {
    grid-template-columns: 1fr;
  }
  
  /* Move the image above the form */
  .login-page__image {
    order: -1; /* Move the image to the beginning of the grid */
    height: 50vh; /* Reduce the height of the image */
  }
}
